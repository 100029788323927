<template>
  <Header
    :title="tripStore.current.trip.name"
    subtitle="From 12th of April to 16th of June"
  ></Header>
  <div id="overview_page" class="pages">
      <div class="trip_info">
        <div :class="`fflag ff-wave fflag-${tripStore.current.trip.country}`"></div>
        <div class="location">
          <div class="title">Location</div>
          <div class="name">{{ tripStore.current.trip.location_name }}</div>
        </div>

        <div class="box friends" @click="$router.push({ path: `/trips/${this.$route.params.id}/friends` })">
          <inline-svg
            :src="require('../../../assets/icons/person.svg')"
          ></inline-svg>
          <div class="count">
            {{ friendsCount }}
            <div class="name">person</div>
          </div>
        </div>

        <div class="box destinations" @click="$router.push({ path: `/trips/${this.$route.params.id}/search` })">
          <inline-svg
            :src="require('../../../assets/icons/trips.svg')"
          ></inline-svg>
          <div class="count">
            {{ destinationCount }}
            <div class="name">destinations</div>
          </div>
        </div>
      <!--
          <div class="left">
            <div class="fflag"></div>
            <div class="type">
              <div class="location">{{ this.$store.state.trip.current.location }}</div>
              <div class="trip_type">{{ this.$store.state.trip.current.type_name }}</div>
            </div>
            <div class="destination" @click="$root.switchPage('search')">
              <svg xmlns="http://www.w3.org/2000/svg" width="51" height="68" viewBox="0 0 51 68" fill="none">
                <path d="M25.5 0C27.8375 0 30.0953 0.253584 32.2734 0.760752C34.4516 1.26792 36.4836 1.97355 38.3695 2.87763C40.2555 3.78171 41.9688 4.88425 43.5094 6.18525C45.05 7.48624 46.3781 8.91955 47.4937 10.4852C48.6094 12.0508 49.4727 13.7376 50.0836 15.5458C50.6945 17.354 51 19.2283 51 21.1688C51 22.6903 50.7875 24.1346 50.3625 25.5017C49.9375 26.8689 49.3 28.2471 48.45 29.6363L25.5 67.74L2.55 29.6363C1.72656 28.2691 1.10234 26.902 0.677344 25.5348C0.252344 24.1677 0.0265625 22.7123 0 21.1688C0 19.2283 0.305469 17.354 0.916406 15.5458C1.52734 13.7376 2.37734 12.0508 3.46641 10.4852C4.55547 8.91955 5.88359 7.49727 7.45078 6.21832C9.01797 4.93938 10.7445 3.83684 12.6305 2.9107C14.5164 1.98457 16.5484 1.26792 18.7266 0.760752C20.9047 0.253584 23.1625 0 25.5 0ZM25.5 29.6363C26.9078 29.6363 28.2227 29.4158 29.4445 28.9747C30.6664 28.5337 31.7555 27.9273 32.7117 27.1556C33.668 26.3838 34.3984 25.4907 34.9031 24.4764C35.4078 23.462 35.6734 22.3595 35.7 21.1688C35.7 20.0001 35.4344 18.9086 34.9031 17.8942C34.3719 16.8799 33.6414 15.9758 32.7117 15.182C31.782 14.3881 30.7062 13.7817 29.4844 13.3628C28.2625 12.9438 26.9344 12.7233 25.5 12.7013C24.0922 12.7013 22.7773 12.9218 21.5555 13.3628C20.3336 13.8038 19.2445 14.4102 18.2883 15.182C17.332 15.9537 16.6016 16.8468 16.0969 17.8611C15.5922 18.8755 15.3266 19.978 15.3 21.1688C15.3 22.3375 15.5656 23.429 16.0969 24.4433C16.6281 25.4576 17.3586 26.3617 18.2883 27.1556C19.218 27.9494 20.2937 28.5558 21.5156 28.9747C22.7375 29.3937 24.0656 29.6142 25.5 29.6363Z" fill="white"></path>
              </svg>
              {{ destinationCount }}
            </div>
          </div>
          <div class="bottom">
            <div class="image" v-bind:class="$store.state.trip.current.type_name"/>
            <div class="users" @click="$root.switchPage('invite')">
              <svg xmlns="http://www.w3.org/2000/svg" width="62" height="52" viewBox="0 0 62 52" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1534 11.1428C28.1534 17.3086 24.4052 22.2857 19.727 22.2857C15.0488 22.2857 11.2725 17.3086 11.2725 11.1428C11.2725 4.97714 15.0488 0 19.727 0C24.4052 0 28.1534 4.97714 28.1534 11.1428ZM50.6989 11.1428C50.6989 17.3086 46.9507 22.2857 42.2725 22.2857C37.5943 22.2857 33.818 17.3086 33.818 11.1428C33.818 4.97714 37.5943 0 42.2725 0C46.9507 0 50.6989 4.97714 50.6989 11.1428ZM19.7273 29.7143C13.1609 29.7143 0 34.06 0 42.7143V48.2857C0 50.3286 1.26819 52 2.81819 52H36.6365C38.1865 52 39.4546 50.3286 39.4546 48.2857V42.7143C39.4546 34.06 26.2937 29.7143 19.7273 29.7143ZM39.539 29.9C40.5253 29.7886 41.4553 29.7143 42.2726 29.7143C48.839 29.7143 61.9999 34.06 61.9999 42.7143V48.2857C61.9999 50.3286 60.7318 52 59.1818 52H44.5835C44.8935 50.8486 45.0908 49.5857 45.0908 48.2857V42.7143C45.0908 37.2543 42.8644 33.1315 39.6517 30.0486C39.6432 30.0374 39.6346 30.0227 39.6253 30.0067C39.6039 29.9699 39.5783 29.9259 39.539 29.9Z" fill="white" fill-opacity="0.8"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1534 11.1428C28.1534 17.3086 24.4052 22.2857 19.727 22.2857C15.0488 22.2857 11.2725 17.3086 11.2725 11.1428C11.2725 4.97714 15.0488 0 19.727 0C24.4052 0 28.1534 4.97714 28.1534 11.1428ZM50.6989 11.1428C50.6989 17.3086 46.9507 22.2857 42.2725 22.2857C37.5943 22.2857 33.818 17.3086 33.818 11.1428C33.818 4.97714 37.5943 0 42.2725 0C46.9507 0 50.6989 4.97714 50.6989 11.1428ZM19.7273 29.7143C13.1609 29.7143 0 34.06 0 42.7143V48.2857C0 50.3286 1.26819 52 2.81819 52H36.6365C38.1865 52 39.4546 50.3286 39.4546 48.2857V42.7143C39.4546 34.06 26.2937 29.7143 19.7273 29.7143ZM39.539 29.9C40.5253 29.7886 41.4553 29.7143 42.2726 29.7143C48.839 29.7143 61.9999 34.06 61.9999 42.7143V48.2857C61.9999 50.3286 60.7318 52 59.1818 52H44.5835C44.8935 50.8486 45.0908 49.5857 45.0908 48.2857V42.7143C45.0908 37.2543 42.8644 33.1315 39.6517 30.0486C39.6432 30.0374 39.6346 30.0227 39.6253 30.0067C39.6039 29.9699 39.5783 29.9259 39.539 29.9Z" fill="white"></path>
              </svg>
              {{ friendsCount }}
            </div>
          </div>-->
        </div>

        <h2>
          Destinations
        </h2>
        <div id="destinations">
          <div class="destination" v-for="dest in destinationList" :key="dest.id">
            <a :href="`/trips/${this.$route.params.id}/destinations/${dest.destination_id}`" @click.prevent="this.$router.push({ path: `/trips/${this.$route.params.id}/destinations/${dest.destination_id}` })">
              <div class="picture" :style="{ 'background-image': 'url(https://destinations.trivvel.com/destinations/' + dest.destination_id + '/default.jpg)' }"></div>
            </a>
            <div class="likes">
              <a href="#" @click.prevent="likeDestination(dest.id)" v-bind:class="isLiked(dest.likes)">
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="48" viewBox="0 0 57 48" fill="none" class="">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9858 47.9564C27.7192 47.9564 27.4636 47.8499 27.2758 47.6606L4.4158 24.8006C-1.0642 19.3206 -1.5842 10.3806 3.6458 4.67063C6.28649 1.77863 9.99508 0.0924572 13.9103 0.0036893C17.8255 -0.0850786 21.6067 1.43128 24.3758 4.20063L27.9658 7.79063L28.1958 7.60063L31.2858 4.52063C34.1062 1.70227 37.9286 0.116503 41.9158 0.110626C45.4282 0.0924845 48.8191 1.39531 51.4158 3.76063C54.3064 6.40271 55.9908 10.1121 56.0777 14.0273C56.1645 17.9425 54.6464 21.7229 51.8758 24.4906L28.6958 47.6606C28.508 47.8499 28.2524 47.9564 27.9858 47.9564ZM14.0227 3.9774C11.1864 4.04169 8.5011 5.26856 6.5958 7.37063C2.90593 11.6489 3.18642 18.0609 7.2358 22.0006L28.0158 42.7206L49.0958 21.7006C51.1094 19.702 52.2133 16.9639 52.149 14.1276C52.0847 11.2912 50.8579 8.60593 48.7558 6.70063C46.8963 5.01036 44.4686 4.08212 41.9558 4.10063C39.0309 4.11276 36.2285 5.27682 34.1558 7.34063L30.8658 10.6306C30.8139 10.6825 30.7587 10.7245 30.7039 10.766C30.6638 10.7965 30.6239 10.8268 30.5858 10.8606L25.2658 16.1806C24.7603 16.6862 24.0234 16.8836 23.3329 16.6986C22.6423 16.5135 22.1029 15.9741 21.9179 15.2836C21.7328 14.593 21.9303 13.8562 22.4358 13.3506L25.1758 10.6106L21.5958 7.03063C19.5972 5.01705 16.8591 3.91311 14.0227 3.9774Z" fill="#FFA8A8"></path>
                </svg>
              </a>
            </div>
            <div class="info">
              <div class="name">
                <a href="#" @click="$root.showDestination(this, dest.id)">{{ dest?.info?.name }}</a>
              </div>
              <div class="category">{{ truncateText(dest?.info?.description?.en || '', 80) }}</div>
            </div>
            <div class="creator">
              <div class="city">{{ dest?.info?.category_name }} - {{ dest?.info?.location?.city }}</div>
              <div class="no_likes">{{ dest.likes_count || 0 }} Like(s)</div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import DestinationService from "@/services/destination.service";
import { useTripStore } from '../../../store/trip';
import { useAuthStore } from '../../../store/auth';

export default {
  data() {
    return {
      sorting: 1,
      tripStore: useTripStore(),
      authStore: useAuthStore(),
      destinations: [],
      destinations_info: []
    }
  },
  computed: {
    filterOnComments() {
      return []; // this.$store.state.trip.destinations.slice(0).sort((a, b) => a.trip_destination.comment_count < b.trip_destination.comment_count ? this.sorting : -this.sorting )
    },
    filterOnLikes() {
      return []; //this.$store.state.trip.destinations.slice(0).sort((a, b) => a.trip_destination.like_count < b.trip_destination.like_count ? this.sorting : -this.sorting )
    },
    destinationList() {
      return this.tripStore.current.destinations.slice(0).sort((a, b) => a.created_at < b.created_at ? this.sorting : -this.sorting )
    },
    destinationCount() {
      return this.tripStore.current.destinations.length;
    },
    friendsCount() {
      return this.tripStore.current.trip?.users?.length;
    }
  },
  methods: {
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }

      return text.substr(0, length) + '\u2026'
    },
    isLiked(likes){
      return DestinationService.isLiked(likes, this.authStore.state.user.id)
    }
  }
}
</script>
<style lang="sass" scoped>

.trip_info
  display: flex
  padding: 2rem
  align-items: center
  justify-content: flex-start

  @media (max-width: $media-mobile)
    flex-direction: column

  .fflag
    width: 50px
    height: 50px
    border-radius: 60px
    box-shadow: 0px 1px 5px #ccc
    margin-right: 3rem

  .location
    font-family: 'Jost'
    font-weight: 500
    text-align: left
    font-size: 2.4rem

    .title
      color: #BCBCBC

    .name
      font-size: 2rem

  .box
    background-color: white
    border-radius: 60px
    display: flex
    padding: 1rem 4rem
    margin-left: 4rem
    align-self: flex-end

    @media (max-width: $media-mobile)
      align-self: center
      margin-top: 4rem
      width: 80%
      margin-left: 0

    svg
      width: 75px
      fill: #A0EDDC

    .count
      margin-left: 2rem
      display: flex
      flex-direction: column
      text-align: left
      font-size: 5rem
      line-height: 5rem
      color: #BCBCBC
      font-weight: 500

      .name
        line-height: 3rem
        font-size: 1.5rem

h2
  font-size: 2.4rem
  font-weight: 500
  margin-bottom: 2rem
  margin-top: 1rem
  padding-bottom: 0.2rem
  text-align: left

#destinations
  display: flex
  flex-direction: row
  gap: 3rem
  padding-bottom: 6rem
  flex-wrap: wrap

  @media (max-width: $media-mobile)
    flex-direction: column

  .destination
    display: flex
    flex-direction: column
    text-align: center
    background-color: white
    width: 30%
    text-align: left
    border-radius: 10px
    box-shadow: -1px 1px 10px 0px rgba(0,0,0,0.1)

    @media (max-width: $media-mobile)
      width: 100%
      max-width: 100%

    .picture
      border: 1px solid #ddd
      width: 100%
      height: 150px
      margin-right: 1rem
      border-radius: 6px
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      background-size: cover
      background-position: center

    .info
      padding: 1.8rem
      height: 12rem

      .name
        font-size: 2.2rem
        font-weight: bold
        font-family: 'Jost'

      .category
        font-weight: 500
        font-size: 1.3rem

      a
        color: #666

        &:hover
          color: $primary-color

    .likes
      position: relative
      margin-top: -25px
      display: flex
      justify-content: flex-end
      height: 22px
      margin-right: 10px

      a
        border: 1px dashed #f79191
        display: block
        border-radius: 60px
        padding: 10px
        height: 50px
        width: 50px
        background-color: white

        &.liked
          border: 0
          background-color: #ea5252

          svg
            path
              fill: white

        svg
          height: 32px
          width: 29px

    .creator
      text-align: right
      background-color: #eee
      height: 32px
      padding: 0 1rem
      line-height: 32px
      border-radius: 10px
      border-top-left-radius: 0px
      border-top-right-radius: 0px
      display: flex
      justify-content: space-between
      flex-direction: row

      .city
        font-weight: 400
        font-size: 1.2rem

      .no_likes
        font-size: 1.2rem

</style>
